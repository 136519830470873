/** Ensure that some assets are copied that aren't by default **/
.non-existent {
    background: url(../../node_modules/leaflet/dist/images/marker-icon-2x.png),
        url(../../node_modules/leaflet/dist/images/marker-shadow.png),
        url(../logo-small.png),
        url(../logo.png),
        url(../logo.svg);
}

html, body { font-size: 100%; }
body {
    display: grid;
    
    grid-template-columns: 10em auto 10em;
    grid-template-rows: auto auto;
    grid-template-areas: ". header ."
        ". . .";
    
    font-family: sans-serif;
	margin: 0; padding: 0;
}

body > h1 {
    grid-area: header;
    justify-self: center;
    align-self: start;
    
    margin: 0;
	padding: 0.25em 0.45em;
	
	background: rgba(255, 255, 255, 0.5);
	border-radius: 0 0 0.25em 0.25em;
    
    z-index: 100;
}

main {
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	
	z-index: 50;
}

.sms-panel {
    top: initial !important; bottom: 0;
    z-index: 100 !important;
}

button, a { cursor: pointer; }
button.selected {
    font-weight: bolder;
}

.working { cursor: progress !important; }

.working-visual::after {
    content: "";
    
    position: relative; top: 0; left: 0;
    width: 100%; height: 100%;
    display: block;
    z-index: 10000;
    
    background: url(images/spinning-circles.svg) center center / 25% no-repeat;
    
    cursor: progress !important;
    pointer-events: none;
}


.leaflet-control-attribution a {
    color: #0050B6;
}


.easyPrintSizeMode .HiResLandscape {
    background-image: url(images/openiconic-image.png);
}
