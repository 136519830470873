.leaflet-popup-content {
	width: auto !important;
}

h2.device-name { text-align: center; }
.device-name::after {
	content: "#" attr(data-id) ", " attr(data-minutes_ago);
	float: right; margin-right: 1em;
	
	font-size: 80%;
	color: hsl(230, 45%, 65%);
}

.tab-panes .tab-pane {
	display: none
}
.tab-panes .active {
	display: block
}

.tabs {
	list-style-type: none;
	margin: 0; padding: 0;
	
	min-width: 40em;
	
	display: flex;
	flex-direction: row;
}
.tabs li {
	flex: 1;
	
	padding: 0 0.25em;
	
	text-align: center;
	font-size: 120%;
}
.tabs li a {
	display: inline-block; width: 100%;
	box-sizing: border-box;
	padding: 0.45em;
	
	opacity: 0.5;
	background: hsla(252, 82%, 66%, 0.53);
	border-radius: 0.25em;
	
	color: hsl(252, 100%, 59%);
	text-decoration: none;
}
.tabs li a.active { opacity: 1; }

.tab-panes {
	display: flex; justify-content: center;
	
	min-height: 20em;
	padding-top: 1em;
}

.device-params { margin: 0 auto; }

.device-property-table {
	border-collapse: collapse;
}
.device-property-table tr:nth-child(odd) {
	background: hsla(222, 100%, 70%, 0.25);
}
.device-property-table th {
	padding-right: 2em;
}
.device-property-table td:nth-child(2) {
	border-left: 0.2em solid hsl(222, 100%, 59%);
	padding-left: 0.5em;
}

.button-array {
	list-style-type: none;
	padding: 0;
	
	display: flex;
	flex-direction: row;
}
.button-array button {
	margin: 0.25em;
	
	background: hsla(252, 82%, 66%, 0.53);
	border-radius: 0.25em;
	border: none;
	
	color: hsl(252, 100%, 59%);
}
