.nanoModal {
	max-width: 50vw;
	max-height: 70vh;
	overflow-y: scroll;
}

.nanoModal h1 {
	margin-top: 0;
}

.nanoModal.reverse .nanoModalButtons {
	position: absolute; top: 0.5em; right: 0.5em;
	display: inline-block !important;
	margin: 0;
	border: 0;
}
